<template>
    <div class="wrapper" draggable="false" style="display: flex;justify-content: center;overflow: auto;">

        <div class="yinzhang"  v-show="showbtn&&jsonObject.signType==2 && jsonObject.applyState>0 " style="position: fixed;
                    left: 16px;top: 20%;z-index: 1;display: none;"   :style="{left: z_left + 'px' }">
            <div style="font-weight:600;">印章</div>
            <div style="margin:16px 0">将印章拖动到文件区域即可盖章 </div>
            <div style="border:1px dotted #ccc;padding:0px;    display: flex;justify-content: center;">
                <img style="width:200px;height:200px;cursor: move; " ref="stamp" src="../../assets/images/tuzhangnew.png" alt="Stamp" :draggable="mydraggable" @dragstart="handleDragStart">
                <!-- <img style="width:200px;height:200px;cursor: move; " ref="stamp" src="../../assets/images/tuzhangnew.png" alt="Stamp" :draggable="mydraggable" @touchstart="handleDragStart" @touchmove="doDrag" @touchend="endDrag"> -->
            </div>
            <img style='display:none;cursor: pointer;' ref="close" class="delete-icon" draggable="false" src="../../assets/images/close.jpg">
        </div>
        <div class="channelWrap" draggable="false" style="width:794px;">
            <!-- 常规A4纸 794px 1123px -->
            <div draggable="false" style="width:794px;verflow: auto;position: relative;" ref="pdfContainer" @drop="handleDrop" @dragover='handDragover'>
                <!-- <pdf ref='pdf' :src="jsonObject.data"  @click="handlePdfClick"></pdf> -->
                <!--  -->
                <pdf ref="pdf" v-for="item in numPages" :key="item" :page="item" :src="jsonObject.fulldata" @click="handlePdfClick"></pdf>
                <!-- <pdf ref='pdf' src="https://yunxi.jindingaus.com/downloadFlowDoc/%E4%B8%AA%E4%BA%BA%E6%88%BF%E5%B1%8B%E7%A7%9F%E8%B5%81%E5%90%88%E5%90%8C%20--%E6%BC%94%E7%A4%BA.pdf"></pdf> -->
            </div>
            <div  v-show="jsonObject.signType==2 && jsonObject.applyState==0 " class="tips" :style="{right: btn_right-60 + 'px' }" style="color:red;">
                <div>
                    <div style="display: flex;align-items: center;"><img style="margin-right:8px;" src="../../assets/images/info-circle.png" alt="">温馨提示：</div>
                    <div style="margin-top:4px;letter-spacing: 1px;">
                        <div>
                            1.协议填写了银行账户信息的只能生成已盖章协议，需要财务审核通过后方可下载打印协议
                        </div>
                        <div style="margin-top:8px;letter-spacing: 1px;">
                            2.协议未填写银行账户信息则只能生成未盖章协议，下载打印后手动完善银行账户信息
                        </div>
                    </div>

                </div>
            </div>
            <div class="operationbtn" v-show="showbtn&&jsonObject" :style="{right: btn_right + 'px' }">
                <div v-show="jsonObject.signType==2 && jsonObject.applyState==0 " style="display: flex;flex-direction: column;">
                    <el-button v-if="jsonObject.bankName&&jsonObject.bankAccountName&&jsonObject.bankNum" type="primary" size="medium" style="padding: 4px 15px;" @click.stop="saveApplyState(2)">
                        生成已盖章协议<br> <span style="color:red;margin-top:2px;display: block;">（需要财务审核）</span>
                    </el-button>

                    <el-button  v-else  type="primary" size="medium" style="padding: 4px 15px;"  @click.stop="saveApplyState(1)">生成并打印协议<br><span style="color:red;margin-top:2px;display: block;">（未盖章）</span> </el-button>
                    
                </div>

                <el-button type="primary" v-show="jsonObject.signType==2 && jsonObject.applyState>0 " @click.stop="saveStampState(1)" >同意盖章</el-button>
                <el-button type="primary" v-show="jsonObject.signType==2 && jsonObject.applyState>0 " @click.stop="saveStampState(2)" >拒绝盖章</el-button>
                <el-button type="primary" v-show="jsonObject.signType!=2" @click.stop="handleAddBtn">确认生成电子合同</el-button>

               
            </div>

        </div>
        <!--  -->
        <el-dialog class="config-edit" :visible="viewIsReady" @close="handleClose" title="温馨提示" width="500px">
            <div style="line-height:20px;">
                请认真核对渠道商的信息（基础信息与账户信息），生成协议后将不能进行修改。
            </div>

            <div slot="footer">
                <el-button @click="handleClose" type="primary">知道啦</el-button>
            </div>
        </el-dialog>

        <el-dialog class="config-edit" :visible="infodia" @close="infodia = false" title="温馨提示" width="500px">
            <div style="line-height:20px;margin-bottom:24px;">
                请仔细核对签署协议的客户信息，如信息错误将不能进行电子协议的签署。客户信息如下：
            </div>
            <div>
                <div style="margin-bottom:12px;">客户姓名：<span>{{jsonObject.custName}}</span></div>
                <div style="margin-bottom:12px;">手机号：<span>{{jsonObject.custPhone}}</span></div>
                <div style="margin-bottom:12px;">身份证号：<span>{{jsonObject.custIdcard}}</span></div>
                <div style="margin-bottom:12px;">公司：<span>{{jsonObject.companyName}}</span></div>
            </div>

            <div slot="footer">
                <el-button @click="infodia = false">取消</el-button>
                <el-button @click="sureDia" type="primary">已核对</el-button>
            </div>
        </el-dialog>
        <!--  -->
        <!--  -->
        <el-dialog class="config-edit" :visible="surescuessDia" @close="closeSure" title="温馨提示" width="500px">
            <div style="line-height:20px;">
                已开启电子合同签署流程，会短信通知到签署人(渠道商先签公司后签署)
            </div>
            <div slot="footer">
                <el-button @click="closeSure" type="primary">知道啦</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script>
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
export default {
    components: { pdf },
    props: {},
    data() {
        return {
            id: "",
            sysCode: "",
            jsonObject: "",
            pdfurl: "",
            viewIsReady: false,
            infodia: false,
            surescuessDia: false,

            numPages: "", // pdf 文件总页数
            // pdfSrc: "",
            // pdfUrl: "", // pdf 文件的路径，可以是本地路径，也可以是在线路径

            pdfElement: "",

            deleteIconX: 0,
            deleteIconY: 0,
            mydraggable:true,

            windowWidth: 0,
            z_left:0,
            yinzhang_w:220,
            btn_right:0,
            btn_w:140,
            showbtn:true,
            todayStr:'',
        };
    },
    created() {
        console.log(this.$route);
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
        }
        if (this.$route.query.sysCode) {
            this.sysCode = this.$route.query.sysCode;
        }
        // let urlQuery = this.getUrlQuery()
        // if(urlQuery.id){
        //     this.id = urlQuery.id
        // }
        // if(urlQuery.sysCode){
        //     this.sysCode = urlQuery.sysCode
        // }

        this.$store.commit("setShowMenu", { showMenu: false }); //隐藏菜单
        this.getPreviewPdfById();


        let today = new Date()
         this.todayStr = this.getDateStr(today);
         console.log(this.todayStr);
    },
    mounted() {
        this.getWindowWidth();
         window.addEventListener('resize', this.getWindowWidth);

    },
    watch: {},
    computed: {},
    
    methods: {
        getDateStr (date) {
			if (date instanceof Date) {
				let Y = date.getFullYear();
				let M = date.getMonth() + 1;
				let D = date.getDate();
				M = M < 10 ? `0${M}` : M;
				D = D < 10 ? `0${D}` : D;
				return `${Y}-${M}-${D}`;
			}
			else return ""
		},
         getWindowWidth() {
            this.windowWidth = window.innerWidth;
            const element = document.querySelector('.yinzhang');
            // 获取元素的宽度
            const yinzhang_w = element.offsetWidth;
            console.log(yinzhang_w);
            this.z_left = (this.windowWidth-794)/2-this.yinzhang_w
            this.btn_right = (this.windowWidth-794)/2-this.btn_w

            
        },
        handDragover(event){
             event.stopPropagation(); //阻止火狐会打开新的窗口
            event.preventDefault();
        },

        handleDrop(event) {

            event.stopPropagation(); //阻止火狐会打开新的窗口
            event.preventDefault();
            const pdfContainer = this.$refs.pdfContainer;
            const stamp = this.$refs.stamp;

            // 获取 PDF 页面容器相对于视口的位置
            const rect = pdfContainer.getBoundingClientRect();

            // 计算鼠标相对于 PDF 页面的偏移量
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;

            // 获取 PDF 页面容器的尺寸
            const containerWidth = pdfContainer.offsetWidth;
            const containerHeight = pdfContainer.offsetHeight;

            // 获取盖章图片的尺寸
            const stampWidth = stamp.offsetWidth;
            const stampHeight = stamp.offsetHeight;

            // 计算盖章图片在 PDF 页面容器上的位置

            //   const x = (mouseX / containerWidth) * 100; // 转换为百分比位置
            //   const y = (mouseY / containerHeight) * 100; // 转换为百分比位置


            // 计算所在页数
            const pageIndex = this.getPageIndexFromPosition(
                mouseY,
                containerHeight
            );
            console.log("所在页数:", pageIndex + 1);

            const x = mouseX; // 转换为百分比位置
            const y = mouseY + 10; // 转换为百分比位置

            // const x = 141; // 转换为百分比位置
            // const y = 152; // 转换为百分比位置

            // 绘制盖章图片到 PDF 页面容器上
            const stampDiv = document.createElement("div");
            stampDiv.classList.add("stamp-container");
            stampDiv.style.position = "absolute";
            //   stampDiv.style.left = `${x}%`;
            //   stampDiv.style.top = `${y}%`;
            console.log("left", x, "top", y);

            stampDiv.dataset.page = pageIndex + 1; // 存储页数信息
            stampDiv.dataset.x = x; // 存储 x 坐标信息
            // stampDiv.dataset.y = y; // 存储 y 坐标信息
            // 每页的高度
            let pageH = (containerHeight / this.numPages).toFixed(2);
            stampDiv.dataset.y = pageH - (y - pageIndex * pageH);

            stampDiv.style.left = `${x}px`;
            stampDiv.style.top = `${y}px`;

           
            const close = this.$refs.close;
            stampDiv.style.transform = "translate(-50%, -50%)"; // 居中处理
            stampDiv.innerHTML = `
                <div style="display: flex;flex-direction: column;align-items: center;border: 1px dotted #ccc;padding: 4px;">
                    <img draggable="false" src="${stamp.src}" style="width: ${stampWidth}px; height: ${stampHeight}px;">
                    <div class='pdfzy'>
                     <i class="el-icon-delete delete-icon" style="color: #fff;cursor: pointer;font-size: 16px;"></i>
                    </div>
                </div>
                `;

            stampDiv.addEventListener("mouseenter", (e) => {
                const deleteIcon = e.target.querySelector(".pdfzy");
                deleteIcon.style.display = "flex";
            });
            stampDiv.addEventListener("mouseleave", (e) => {
                const deleteIcon = e.target.querySelector(".pdfzy");
                deleteIcon.style.display = "none";
            });

            pdfContainer.appendChild(stampDiv);
            // this.mydraggable = false

            stampDiv.setAttribute("draggable", "false");

            const deleteIcon = stampDiv.querySelector(".pdfzy");
            deleteIcon.addEventListener("click", () => {
                stampDiv.parentNode.removeChild(stampDiv);
                this.mydraggable = true
            });
            let positions = this.getAllStampPositions()
            console.log('positions',positions);
            if(positions.length>=3){
                this.mydraggable = false
            }else{
                this.mydraggable = true
            }
        },

        getPageIndexFromPosition(y, containerHeight) {
            const pageIndex = Math.floor((y / containerHeight) * this.numPages);
            return Math.max(0, Math.min(pageIndex, this.numPages - 1));
        },

        handleDragStart(event) {
            console.log("handleDragStart");
            // 开始拖拽时设置拖拽数据
            event.dataTransfer.setData("text/plain", event.target.src);
        },
        doDrag(ev) {
            console.log('doDrag');
        },
        endDrag(ev) {
            console.log('endDrag');
        },
        

        handlePdfLoaded() {
            console.log("handlePdfLoaded");
        },

        handlePdfClick(event, pages) {
            //   console.log(event.clientX,'__',event.clientY);
            // console.log('handlePdfClick','pages',pages);
            let pdfElement = "";

            const pdfs = this.$refs.pdf; // 获取所有的 PDF 组件
            if (pdfs instanceof Array) {
                pdfs.forEach((pdf, index) => {
                    if (index + 1 == pages) {
                        pdfElement = pdf.$el; // 获取 PDF 元素
                    }
                });
            }

            //   const pdfElement = this.$refs.pdf.$el; // 获取 PDF 元素
            const rect = pdfElement.getBoundingClientRect(); // 获取 PDF 元素相对于视口的位置

            const mouseX = event.clientX - rect.left; // 获取鼠标点击的 X 坐标
            const mouseY = event.clientY - rect.top; // 获取鼠标点击的 Y 坐标

            // 获取当前页数
            // const currentPage = Math.ceil(mouseY / (pdfElement.clientHeight / this.numPages));

            // 输出坐标和页数
            console.log("点击位置坐标 (X, Y):", mouseX, mouseY);
            console.log("当前页数:", pages);
            // this.viewIsReady = true;
        },
        closeSure() {
            this.surescuessDia = false;
            this.$router.push({
                path: `/customer/channelLineList`,
                // query: {id: data.id}
            });
        },
        // 创建渠道合同
        async sureDia() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/channelContract/createChannelContract",
                method: "post",
                data: {
                    treatyId: this.jsonObject.treatyId,
                    sysCode: this.sysCode,
                    path: this.jsonObject.newFilePath,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.infodia = false;
                this.surescuessDia = true;
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        // 生成并打印协议
        async saveApplyState(type) {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/channel/saveApplyState",
                method: "post",
                data: {
                    id:this.jsonObject.treatyId,
                    type:type,
                    winkPath:this.jsonObject.data
                },
            });
            loading.close();
            let tiptext='协议已下载，渠道商签署后需回传签署后的协议'
            if(type==2){
                tiptext='请提醒财务进行协议盖章，盖章后方可下载合同与渠道商签署'
            }
            if (res.code === 2000) {
                if(res.data.path && type==1){
                    this.goBirthDownPdf(this.$baseURL + "/" + res.data.path,res.data.path)
                }
                this.$confirm(
                    tiptext,
                    "温馨提示",
                    {
                        confirmButtonText: "知道啦",
                        showCancelButton: false
                    }
                )
                    .then(() => {
                        this.showbtn= false
                        window.close()
                        // this.$router.push({
                        //     path: `/customer/channelLineList`,
                        //     // query: {id: data.id}
                        // });
                    })
                    .catch(() => {
                        console.log("取消");
                    });



            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        goBirthDownPdf(downurl,filePath) {
            // 下载
            let url = downurl;
            // 使用 XMLHttpRequest 或 fetch 下载文件
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";

            xhr.onload = function () {
                if (xhr.status === 200) {
                    // 创建 Blob 对象
                    let blob = xhr.response;

                    // 创建下载链接
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = filePath.split('/').pop(); // 设置下载文件的名称

                    // 添加到文档中并触发点击
                    document.body.appendChild(a);
                    a.click();

                    // 移除元素
                    document.body.removeChild(a);
                }
            };
            xhr.send();
            this.$message({
                message: "下载成功",
                type: "success",
            });
        },
        getAllStampPositions() {
            const pdfContainer = this.$refs.pdfContainer;
            // console.log(stampContainers); // 输出获取到的元素列表
            const allStamps = Array.from(
                pdfContainer.querySelectorAll(".stamp-container")
            );
            const positions = allStamps.map((stamp) => {
                return {
                    page: stamp.dataset.page,
                    x: stamp.dataset.x,
                    y: stamp.dataset.y,
                };
            });
            console.log(positions);
            return positions;
        },
        
        // 同意/拒绝盖章
        async saveStampState(type) {
            if(type==1){
                let positions = this.getAllStampPositions()
                if(positions.length!=3){
                     this.$message({
                        message: "协议盖章区域缺失，一共有3处需要盖章",
                        type: "warning"
                    });

                }else{

                     this.$confirm(
                    '盖章位置已生成，确定盖章吗？',
                    "温馨提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                    .then(() => {
                        this.saveStampStateAfter(type,positions)

                    })
                    .catch(() => {
                        console.log("取消");
                    });


                }
            }else{
                this.saveStampStateAfter(type)
            }
        
        },
        async saveStampStateAfter(type,positions) {
            let param = {
                "id":this.jsonObject.treatyId,
                "state":type,
                // "x":0,
                // "y":0,
                // "pageNum":0
                axisListStr:'',

            }
            let axisList = []

            if(positions){
                // param.x = Number(positions[0].x) 
                // param.y = Number(positions[0].y) 
                // param.pageNum = Number(positions[0].page) 
                positions.forEach(element => {
                    axisList.push({
                        x:Number(element.x),
                        y:Number(element.y),
                        pageNum : Number(element.page) 
                    })
                });
                param.axisListStr = JSON.stringify(axisList)
            }
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/channel/saveStampState",
                method: "post",
                data:param,
            });
            loading.close();
            if (res.code === 2000) {
                this.$confirm(
                    '操作成功，已通知协议对接人',
                    "温馨提示",
                    {
                        confirmButtonText: "知道啦",
                        showCancelButton: false
                    }
                )
                    .then(() => {
                        this.showbtn= false
                        window.close()

                        // this.$router.push({
                        //     path: `/customer/channelLineList`,
                        //     // query: {id: data.id}
                        // });
                    })
                    .catch(() => {
                        console.log("取消");
                    });



            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        

        



        handleAddBtn() {
            this.infodia = true;
        },
        handleClose() {
            this.viewIsReady = false;
        },
        // 获取渠道协议附件
        // getPreviewPdfById(){
        //     this.viewIsReady = true
        // },
        async getPreviewPdfById() {
            const loading = this.$loading({
                lock: true,
                text: "协议生成中，请稍后...",
                //   spinner: 'el-icon-loading',
                //   background: 'rgba(0, 0, 0, 0.7)'
            });

            const res = await this.$axios({
                url: "/api/channelContract/previewPdfById",
                method: "post",
                data: {
                    id: this.id,
                    sysCod: this.sysCode,
                },
            });
            loading.close();
            if (res.code === 2000) {
                if (res.data.jsonObject.data.includes("http")) {
                    res.data.jsonObject.fulldata =res.data.jsonObject.data
                } else {
                    res.data.jsonObject.fulldata =this.$baseURL+'/'+res.data.jsonObject.data
                }
                // res.data.jsonObject.data='http://120.25.85.101:7010/upload/treatyPdf/%E4%B8%AA%E4%BA%BA%E6%B8%A0%E9%81%93%E5%8D%8F%E8%AE%AE202303(339).pdf'

                this.jsonObject = res.data.jsonObject;
                if(this.jsonObject.signType==2 && this.jsonObject.applyState>0){
                    this.viewIsReady = false;
                }else{
                    this.viewIsReady = true;
                }

                if(res.data.jsonObject.data){
                    this.showbtn = true
                    this.getTitlePdfurl();
                }else{
                    this.showbtn = false
                }
                console.log(res);
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        getTitlePdfurl() {
            this.pdfSrc = pdf.createLoadingTask(
                { url: this.jsonObject.fulldata },
                CMapReaderFactory
            ); //解决中文乱码问题
            this.pdfSrc.promise
                .then((pdf) => {
                    this.numPages = pdf.numPages;
                })
                .catch((err) => {
                    console.error("pdf 加载失败", err);
                });
        },
        // 获取url参数
        // getUrlQuery() {
        //     // CH-AYM29
        //     // let  url=  'http://192.168.110.136:8020/#/customer/channelPhoneList/add?id=16&chanCode=CH-AYM29&sysCode=AYM'
        //     let  url=  window.location.href
        //     let urlStr = url.split('?')[1]
        //     // 创建空对象存储参数
        //     let obj = {};
        //     // 再通过 & 将每一个参数单独分割出来
        //     let paramsArr = urlStr.split('&')
        //     for(let i = 0,len = paramsArr.length;i < len;i++){
        //         // 再通过 = 将每一个参数分割为 key:value 的形式
        //         let arr = paramsArr[i].split('=')
        //         obj[arr[0]] = arr[1];
        //     }
        //     return obj
        // },
    },
     beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth);
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    min-height: 100%;
    padding: 80px;
    background: #f9f9f9;
}
@media (max-width: 1920px) {
    .wrapper {
        padding: 80px;
    }
}
// 小于1600时
@media (max-width: 1600px) {
    .wrapper {
        padding: 40px;
    }
}
.channelWrap {
    background: #fff;
    height: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    // min-width: 1200px;
    // padding:0 16px;

    display: flex;
    justify-content: center;
}

.pdf-container {
    position: relative;
}
.delete-icon {
    position: absolute;
    cursor: pointer;
}

.tips{
    width: 200px;
   position: absolute;top: 80px;right: 15px;display: flex;
   align-items: flex-start;
}
.operationbtn{
   position: absolute;top: 220px;right: 15px;display: flex;flex-direction: column;
    align-items: center;
}



.operationbtn button{
    margin-bottom: 10px;
    margin-left: 0px;
    padding: 0 15px;
}

</style>

<style>
.pdfzy{
        width: 40px;
    height: 28px;
    position: absolute;
    right: -40px;
    top: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
}

</style>